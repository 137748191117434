<template>
  <a-input
    v-model:value="userName"
    :placeholder="t('common.search')"
    class="bg-ems-tag1 border-none h-[38px] rounded-lg"
  >
    <template #prefix>
      <cds-feather-icons type="search" size="14" />
    </template>
  </a-input>
  <div
    ref="scrollContainer"
    class="mt-[24px] overflow-auto h-[75%] pr-[4px] hidden-scroll"
    @scroll="handleScroll"
  >
    <div v-for="(entry, entryIndex) in listChannels" :key="entryIndex">
      <div>
        <div class="flex items-center space-x-[10px]">
          <img v-if="entry.listData.length > 0" alt="icon" :src="entry.icon" />
          <p class="font-semibold text-white text-[16px]">
            {{ entry.listData.length > 0 ? t(`${entry.value}`) : '' }}
          </p>
        </div>
        <div v-for="(item, index) in entry.listData" :key="index">
          <div
            @click="handleClick(item)"
            :class="`w-full h-[96px]  mt-[11px] ${
              idSelect === item.channelId || hoveredItem === item.channelId
                ? 'bg-ems-gray700'
                : ''
            } flex items-center px-[16px] space-x-[23px] rounded-lg relative cursor-pointer`"
            @mouseenter="hoveredItem = item.channelId"
            @mouseleave="hoveredItem = ''"
          >
            <div
              class="rounded-full w-[64px] h-[64px] bg-ems-boTro2_500 relative"
            >
              <div
                class="rounded-full w-[64px] h-[64px] flex justify-center items-center overflow-hidden flex-shrink-0"
              >
                <p class="font-semibold text-3xl uppercase text-white">
                  {{ item.name && item.name.charAt(0) }}
                </p>
              </div>
              <div
                v-if="item.active"
                class="h-[16px] w-[16px] border-2 rounded-full border-ems-tag1 bg-ems-boTro4_600 absolute bottom-0 right-0"
              ></div>
            </div>
            <div class="space-y-[8px]">
              <p class="text-white font-semibold text-[16px]">
                {{ item.name }}
              </p>
              <div class="flex items-center space-x-[19px] justify-start">
                <p
                  class="text-white font-normal text-[14px] max-w-[200px] truncate py-2"
                >
                  {{
                    item.active
                      ? t('coordinator.online')
                      : t('coordinator.offline')
                  }}
                </p>
                <p class="text-ems-gray200 font-normal text-[12px]">
                  <!-- {{ lastMessage && convertUnixToDate(timestamp) }} -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { TAB_COORDINATION,PEER_TYPE } from '@/util/common-constant.js';
import { defineProps } from 'vue';
import VueTypes from 'vue-types';
import { useRouter, useRoute } from 'vue-router';
import _ from 'lodash';
import { debounce } from 'lodash';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const { state, dispatch } = useStore();
const listChannels = computed(() => state.coordinator.listChannels);
const listChannelsInit = computed(() => state.coordinator.listChannelsInit);
const totalElements = computed(() => state.coordinator.totalElementsChannel);
const listChanenelsFormat = ref(null);
const router = useRouter();
const route = useRoute();
const idSelect = ref('');
const scrollContainer = ref(null);
const DEBOUNCE_TIME = 500;
const page = ref(0);
const userName = ref('');
const hoveredItem = ref('');
const initScroll = ref(true);
onMounted(async () => {
  await fetchData(page.value, true, userName.value);
});

const handleScroll = () => {
  if (scrollContainer.value) {
    const scrollTop = scrollContainer.value.scrollTop;
    const scrollHeight = scrollContainer.value.scrollHeight;
    const offsetHeight = scrollContainer.value.offsetHeight;
    if (scrollTop + offsetHeight >= scrollHeight - 1) {
      if (page.value <= Math.floor(totalElements.value / 10) + 1) {
        page.value++;
        fetchData(page.value, false, userName.value);
      }
    }
  }
};
const fetchData = async (page, firstData, q) => {
  dispatch('coordinator/getListChannels', {
    payload: { page: page, size: 10, q: q, activeOnly: true },
    firstData: firstData,
  }).then(()=>{
    const item = listChannelsInit.value.find(
      (item) => item.channelId === route.query.id
    );
    if (initScroll.value) {
      if (item) {
        handleClick(item);
      } else {
        handleClick(listChannelsInit.value[0]);
      }
      initScroll.value = false;
    }
  })
};

const searchUser = debounce((query) => {
  fetchData(page.value, true, query);
}, DEBOUNCE_TIME);

watch(userName, (newVal) => {
  userName.value = newVal;
  searchUser(newVal);
});

const handleClick = async (item) => {
  let query = {
    id: item.channelId,
    type: PEER_TYPE.CHANNEL,
    tab_list: TAB_COORDINATION.CHANNEL,
    tab_walkie:route.query.tab_walkie,
  };
  dispatch('coordinator/handleSetParams', query);
  idSelect.value = item.channelId;
  dispatch('coordinator/getMessagesByDialogs', {
    dialogId: item.channelId,
    dialogType: 2,
    isChated: true,
  });
  let participants = await dispatch(
    'coordinator/getParticipants',
    item.channelId
  );
  if (participants) {
    let ids = [];
    for (let key in participants) {
      ids.push(participants[key].userId);
    }
    await dispatch('coordinator/getListStatus', ids.join(','));
  }
  let value = {
    dialogType: 2,
    otherInfo: {
      peerAvatarUrl: item.avatarUrl,
      peerId: item.channelId,
      peerName: item.name,
      peerType: 2,
      status: item.active,
      like: item.liked,
    },
    tab: TAB_COORDINATION.CHANNEL,
  };
  dispatch('coordinator/selectValue', value);
};
</script>
<style>

</style>
